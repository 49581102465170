//ProtectedRoute.jsx
/* eslint-disable react/prop-types */

import { Navigate } from "react-router-dom";

import { useContext } from "react";
import { AuthContext } from "context/AuthContext";

function ProtectedRoute({ children, allowedRoles }) {
  const { token, role } = useContext(AuthContext);
  //console.log("ProtectedRoute", token +"/"+ role)

  const isRoleAllowed = allowedRoles.includes(role);
  const accessibleRoute =
    token && isRoleAllowed ? children : <Navigate to="/home" replace={true} />;

  return accessibleRoute;
}

export default ProtectedRoute;
