// localIT.jsx
import { L10n } from "@syncfusion/ej2-base";

L10n.load({
   'it': {
       'schedule': {
           'day': 'Giorno',
           'week': 'Settimana',
           'workWeek': 'Settimana lavorativa',
           'month': 'Mese',
           'agenda': 'Agenda',
           'today': 'Oggi',
           'noEvents': 'Nessun evento',
           'allDay': 'Tutto il giorno',
           'start': 'Inizio',
           'end': 'Fine',
           'more': 'di più',
           'close': 'Chiudere',
           'cancel': 'Annulla',
           'noTitle': '(Nessun titolo)',
           'delete': 'Cancellare',
           'deleteEvent': 'Cancella evento',
           'deleteMultipleEvent': 'Cancella eventi multipli',
           'selectedItems': 'Elementi selezionati',
           'deleteSeries': 'Cancella serie',
           'edit': 'Modificare',
           'editSeries': 'Modifica serie',
           'editEvent': 'Modifica evento',
           'createEvent': 'Crea evento',
           'subject': 'Soggetto',
           'addTitle': 'Aggiungi titolo',
           'moreDetails': 'Più dettagli',
           'save': 'Salva',
           'editContent': 'Modifica il contenuto',
           'deleteContent': 'Sei sicuro di voler cancellare questo evento?',
           'deleteMultipleContent': 'Sei sicuro di voler cancellare gli eventi selezionati?',
           'newEvent': 'Nuovo evento',
           'title': 'Titolo',
           'location': 'Posizione',
           'description': 'Descrizione',
           'timezone': 'Fuso orario',
           'startTimezone': 'Fuso orario di inizio',
           'endTimezone': 'Fuso orario di fine',
           'repeat': 'Ripetere',
           'saveButton': 'Salva',
           'cancelButton': 'Annulla',
           'deleteButton': 'Cancellare',
           'recurrence': 'Ricorrenza',
           'wrongPattern': 'Il modello di ricorrenza non è valido',
           'seriesChangeAlert': 'Le modifiche apportate a specifiche istanze di questa serie verranno annullate e tali eventi corrisponderanno di nuovo alla serie.',
           'createError': 'La durata dell’evento deve essere inferiore alla frequenza con cui si verifica. Accorciare la durata o modificare il modello di ricorrenza nel programma di ricorrenza dell’evento.',
           'recurrenceDateValidation': 'Alcuni mesi hanno meno della data selezionata. Per questi mesi l’occorrenza cadrà nell’ultima data del mese.',
           'sameDayAlert': 'Due occorrenze dello stesso evento non possono avvenire nello stesso giorno.',
           'editRecurrence': 'Modifica ricorrenza',
           'repeats': 'Ripete',
           'alert': 'Avviso',
           'startEndError': 'La data di fine selezionata si verifica prima della data di inizio.',
           'invalidDateError': 'Il valore della data inserita non è valido.',
           'blockAlert': 'Gli eventi non possono essere programmati all’interno del periodo bloccato.',
           'ok': 'OK',
           'yes': 'Sì',
           'no': 'No',
           'occurrence': 'Occorrenza',
           'series': 'Serie',
           'previous': 'Precedente',
           'next': 'Il prossimo',
           'timelineDay': 'Timeline Giorno',
           'timelineWeek': 'Timeline Settimana',
           'timelineWorkWeek': 'Timeline Settimana lavorativa',
           'timelineMonth': 'Timeline Mese',
       }
   }
});