// Profile.jsx
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { BASE_URL } from "../../config";
import uploadImageToCloudinary from "../../utils/uploadCloudinary";
import { useUpdateDoctorMutation } from "../../state/api";

import { AiOutlineDelete } from "react-icons/ai";
import { toast } from "react-toastify";
import { formatDate } from "../../utils/formatDate";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { MY_POLICY_ID } from "../../config";
import Iubenda from "react-iubenda-policy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEye,
	faEyeSlash,
	faPlusSquare,
	faMinusSquare,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import {
	useGetDentistByIdQuery,
	useGetPerformanceQuery,
	useGetServicesQuery,
	useGetSpecialBrandQuery,
} from "../../state/api";
import { CircularProgress } from "@mui/material"; // Importa CircularProgress per il feedback di caricamento

//=== Disponibilità ===
const AvailabilityOption_def = [
	{ id: 0, label: "Giorni feriali" },
	{ id: 1, label: "Orario continuato" },
	{ id: 2, label: "Sabato" },
];

const Profile = ({ doctorId }) => {
	const [allDataLoaded, setAllDataLoaded] = useState(false);
	const [isRegularProfile, setIsRegularProfile] = useState(false);
	const [isMaxPerformanceSelected, setIsMaxPerformanceSelected] = useState(false);
	const [newInsurance, setNewInsurance] = useState("");

	// Loading performance
	const { data: performanceData } = useGetPerformanceQuery();

	// Loading services
	const { data: servicesData } = useGetServicesQuery();

	// Loading special brands
	const { data: specialBrandData } = useGetSpecialBrandQuery();
	
	// Loading doctor
	const {
		data: doctorData,
		isFetching: isFetchingDoctorData,
		refetch: refetchDoctorData,
	} = useGetDentistByIdQuery(doctorId, { skip: !doctorId });

	// Riapertura pannello
	useEffect(() => {
		if (doctorId && !doctorData && !isFetchingDoctorData) {
			//console.log("refetchDoctorData");
			refetchDoctorData();
		}
	}, [doctorId, doctorData, isFetchingDoctorData, refetchDoctorData]);

	// Controlla se tutti i dati sono stati caricati
	useEffect(() => {
		if (
			doctorData &&
			performanceData &&
			servicesData &&
			specialBrandData
		) {
			setAllDataLoaded(true);
		}
	}, [
		doctorData,
		performanceData,
		servicesData,
		specialBrandData
	]);

	const [selectedFile, setSelectedFile] = useState(null);
	//const [value, setValue] = useState();
	//const navigate = useNavigate();
	const [updateDoctor] = useUpdateDoctorMutation();

	const [formData, setFormData] = useState({
		clinicName: "",
		name: "",
		surname: "",
		gender: "",
		title: "",
		userName: "",
		email: "",
		clinicEmail: "",
		password: "",
		street: "",
		cap: "",
		city: "",
		state: "",
		country: "IT",
		phone: "",
		cellPhone: "",
		healthDirector: "",
		medicalRegister: "",
		photo: null,
		priceRange: "",
		patientType: "",
		consent: "",
		healtInsurance: [],

		// Add fields
		performance: [],
		services: [],
		specialBrand: [],
		availability: [],
		qualifications: [],
		experiences: [],
		specialization: "",
		timeSlots: [],
	});

	const [showPassword, setShowPassword] = useState(false);

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const [selectedPerformance, setSelectedPerformance] = useState([]);
	const [PerformanceOption, setPerformanceOption] = useState([]);
	const [selectedPerformanceCount, setSelectedPerformanceCount] = useState(0);

	const [selectedService, setSelectedService] = useState([]);
	const [serviceOption, setServiceOption] = useState([]);

	const [selectedBrand, setSelectedBrand] = useState([]);
	const [brandOption, setBrandOption] = useState([]);

	const [selectedAvailability, setSelectedAvailability] = useState([]);
	const [availabilityOption, setAvailabilityOption] = useState([]);

	const [showButton, setShowButton] = useState("hidden");
	const [showButtonAdd, setShowButtonAdd] = useState("block");
	const [showButton2, setShowButton2] = useState("hidden");
	const [showButtonAdd2, setShowButtonAdd2] = useState("block");
	const [showButton3, setShowButton3] = useState("hidden");
	const [showButtonAdd3, setShowButtonAdd3] = useState("block");
	const [showButton4, setShowButton4] = useState("hidden");
	const [showButtonAdd4, setShowButtonAdd4] = useState("block");

	useEffect(() => {
		if (doctorData) {
			setFormData({
				clinicName: doctorData?.clinicName,
				name: doctorData?.name,
				surname: doctorData?.surname,
				title: doctorData?.title,
				userName: doctorData?.userName,
				email: doctorData?.email,
				clinicEmail: doctorData?.clinicEmail,
				//password: doctorData?.password,
				// Imposta password a stringa vuota poiché non è incluso nel caricamento
				password: '',
				street: doctorData?.street,
				cap: doctorData?.cap,
				city: doctorData?.city,
				state: doctorData?.state,
				country: doctorData?.country,
				phone: doctorData?.phone,
				cellPhone: doctorData?.cellPhone,
				healthDirector: doctorData?.healthDirector,
				medicalRegister: doctorData?.medicalRegister,
				photo: doctorData?.photo,
				priceRange: doctorData?.priceRange,
				patientType: doctorData?.patientType,
				biography: doctorData?.biography,
				stripeId: doctorData?.stripeId,

				// Add check fields
				performance: doctorData?.performance,
				services: doctorData?.services,
				specialBrand: doctorData?.specialBrand,
				availability: doctorData?.availability,
				qualifications: doctorData?.qualifications,
				experiences: doctorData?.experiences,

				// Optional Fields
				about: doctorData?.about,
				specialization: doctorData?.specialization,
				timeSlots: doctorData?.timeSlots,
				consent: doctorData?.consent,
				healtInsurance: doctorData?.healtInsurance || [],
			});
			
			// Aggiorna solo il valore iniziale dei performance checkbox
			setSelectedPerformance(doctorData.performance || []);
			console.log("selectedPerformance", selectedPerformance);
			if (performanceData && performanceData.length > 0 && doctorData.performance.length > 0) {
				setPerformanceOption(performanceData);
				//console.log("performanceOption", performanceData);
				setShowButtonAdd("hidden");
				setShowButton("block");
			}

			// Aggiorna solo il valore iniziale dei services checkbox
			setSelectedService(doctorData.services || []);
			console.log("selectedService", selectedService);
			//console.log("selectedService-0", doctorData.services);
			//console.log("servicesData-0", servicesData);
			if (servicesData && servicesData.length > 0 && doctorData.services.length > 0) {
				setServiceOption(servicesData);
				//console.log("serviceOption", servicesData);
				setShowButtonAdd2("hidden");
				setShowButton2("block");
			}
			

			// Aggiorna solo il valore iniziale dei special brand checkbox
			setSelectedBrand(doctorData.specialBrand[0] || []);
			//console.log("selectedBrand iniziale", doctorData.specialBrand);
			if (specialBrandData && specialBrandData.length > 0 && doctorData.specialBrand.length > 0) {
				setBrandOption(specialBrandData);
				//console.log("setBrandOption", specialBrandData);
				setShowButtonAdd4("hidden");
				setShowButton4("block");
			}

			// Aggiorna solo il valore iniziale dei availability checkbox
			setSelectedAvailability(doctorData.availability || []);
			//console.log("selectedAvailability", selectedAvailability);
			if (doctorData.availability.length > 0) {
				setAvailabilityOption(AvailabilityOption_def);
				//console.log("selectedAvailability > 0");
				setShowButtonAdd3("hidden");
				setShowButton3("block");
			}
		}
	}, [doctorData, performanceData, servicesData, specialBrandData]);

	//=== Funzioni riusabili
	// Function for adding items
	const addItem = (key, item) => {
		setFormData((prevFormData) => ({
			...prevFormData,
			[key]: [...prevFormData[key], item],
		}));
	};

	// Reusable function for handling changes
	const handleReuseableInputChangeFunc = (key, index, event) => {
		const { name, value } = event.target;
		setFormData((prevFormData) => {
			const updatedItems = [...prevFormData[key]];
			//console.log("updatedItems", updatedItems);
			updatedItems[index][name] = value;
			return {
				...prevFormData,
				[key]: updatedItems,
			};
		});
	};

	// Reusable function for deleting items
	const deleteItem = (key, index) => {
		setFormData((prevFormData) => ({
			...prevFormData,
			[key]: prevFormData[key].filter((_, i) => i !== index),
		}));
		console.log("delete index", index);
		console.log("formData", formData);
	};

	// Funzione checkbox
	const handleInputCheckbox = (e) => {
		const { name, checked } = e.target;
		setFormData({ ...formData, [name]: checked });
	};

	//=== Performance handle ===
	const handlePerformanceChange = (event, id, key) => {
		const { value, checked } = event.target;
		const newValue = checked ? value : "";

		// Crea un nuovo array modificato senza mutare lo stato originale
		const newSelectedPerformance = selectedPerformance.map((item, index) => {
			if (index === 0) {
				// Assumendo che modifichi sempre il primo elemento come nel tuo codice originale
				return { ...item, [`label${id}`]: newValue }; // Aggiorna l'oggetto creandone uno nuovo
			}
			return item;
		});

		setSelectedPerformance(newSelectedPerformance);
		console.log("selectedPerformance", selectedPerformance);

		// Aggiorna formData con lo stesso approccio immutabile
		setFormData((prevFormData) => {
			const updatedItems = prevFormData[key].map((item, idx) => {
				if (idx === 0) {
					return { ...item, [`label${id}`]: newValue };
				}
				return item;
			});

			return { ...prevFormData, [key]: updatedItems };
		});

		// Aggiornamento del conteggio selezionato basato sulla condizione di checked
		if (checked) {
			setSelectedPerformanceCount((prevCount) => prevCount + 1);
		} else {
			setSelectedPerformanceCount((prevCount) => prevCount - 1);
		}
	};

	// Abilita o disabilita il pulsante "Aggiungi Prestazioni" in base al numero di prestazioni selezionate e al tipo di profilo
	useEffect(() => {
		if (doctorData) {
			setIsRegularProfile(doctorData.profileLevel === "Regular");
			setIsMaxPerformanceSelected(selectedPerformanceCount >= 3);
		}
	}, [doctorData, selectedPerformanceCount]);

	// pulsante aggiungi pannello Performace
	const addPerformance = (e) => {
		e.preventDefault();
		const nItem = selectedPerformance.length;
		console.log("nItem", nItem);

		if (nItem === 0) {
			const initLabels = Array.from({ length: 9 }, (_, index) => ({
				[`label${index}`]: "",
			}));
			setSelectedPerformance(initLabels);
			//console.log("selectedPerformance-addItem", selectedPerformance);

			const newLabels = {};
			//for (let i = 0; i < PerformanceOption_def.length; i++) {
			for (let i = 0; i < performanceData.length; i++) {
				newLabels[`label${i}`] = "";
			}
			addItem("performance", newLabels);
			//console.log("addItem");
		}

		setShowButton("block");
		setShowButtonAdd("hidden");
		//setPerformanceOption(PerformanceOption_def);
		setPerformanceOption(performanceData);
	};

	// Cancella il pannello Performance
	const deletePerformance = (e, index) => {
		e.preventDefault(); //gestisce il click del pulsante
		deleteItem("performance", index);
		setShowButton("");
		setPerformanceOption([]);
	};

	//=== Service handle ===
	const handleServiceChange = (event, id, key) => {
		const { value, checked } = event.target;
		console.log("event.target", event.target);
		console.log("formData.services", formData.services)
;
		const newValue = checked ? value : "";

		// Crea un nuovo array modificato senza mutare il vecchio stato
		const newSelectedService = selectedService.map((item, index) => {
			if (index === 0) {
				console.log("index", index);
				// Assumendo che modifichi sempre il primo elemento come nel tuo codice originale
				return { ...item, [`label${id}`]: newValue }; // Aggiorna l'oggetto creandone uno nuovo
			}
			return item;
		});
		console.log("newSelectedService", newSelectedService);

		setSelectedService(newSelectedService);

		// Aggiorna formData 
		setFormData((prevFormData) => {
			/* const updatedItems = prevFormData[key].map((item, idx) => {
			  if (idx === 0) {
				 // Logga l'oggetto corrente prima dell'aggiornamento per confermare la sua struttura
				 console.log("item prima dell'aggiornamento", item);
				 
				 // Crea una copia dell'oggetto e aggiorna solo la proprietà specificata
				 const updatedItem = { ...item[0], [`label${id}`]: newValue };
				 
				 // Logga l'oggetto aggiornato per verificare che l'aggiornamento sia corretto
				 console.log("item dopo l'aggiornamento", updatedItem);
				 
				 return updatedItem;
			  }
			  return item;
			}); */
		 
			// Logga l'array aggiornato per assicurarsi che non ci siano elementi inattesi
			console.log("updatedItems", newSelectedService);
		 
			// Ritorna il nuovo stato di formData con l'array aggiornato sotto la chiave specificata
			return { ...prevFormData, [key]: newSelectedService };
		 });
		 

	};

	// Aggiunge il pannello Servizi
	const addService = (e) => {
		e.preventDefault();
		const nItem = selectedService.length;
		console.log("nItem", nItem);
		console.log("selectedService", selectedService);

		if (nItem === 0) {
			const initLabels = Array.from({ length: 1 }, (_, index) => ({
			//const initLabels = {};	 
			}));

			for (let i = 0; i < 11; i++) {
				 initLabels[0][`label${i}`] = "";
				// initLabels[`label${i}`] = "";
			}
			setSelectedService(initLabels);
			console.log("selectedService-addItem", initLabels);

			// Aggiunge l'array al form
			addItem("services", initLabels);
			console.log("addItem");
		}

		setShowButton2("block");
		setShowButtonAdd2("hidden");
		setServiceOption(servicesData);
	};

	// Cancella il pannello Servizi
	const deleteService = (e, index) => {
		e.preventDefault(); //gestisce il click del pulsante
		deleteItem("services", index);
		setShowButton2("");
		setServiceOption([]);
	};

	//=== Special Brand ===
	const handleBrandChange = (event, id, key) => {
		const { name, value, checked } = event.target;
		let newValue = checked ? value : "";
		console.log("newValue", newValue);
		console.log("idService", id);

		// Aggiorna `selectedBrand` in modo immutabile
		setSelectedBrand((prev) => ({
			...prev,
			[`label${id}`]: newValue,
		}));

		// Aggiornamento del formData se necessario
		/* setFormData((prevFormData) => {
			const updatedItems = [...prevFormData[key]]; 
      
			// Trova l'indice dell'elemento da aggiornare
			//const itemIndex = updatedItems.findIndex(item => item.idService === id);

			//updatedItems[0].find((item) => item.id === id).checked = checked; // Assicurati che l'oggetto esista e che l'ID corrisponda
			//updatedItems[itemIndex] = { ...updatedItems[itemIndex], [`label${id}`]: newValue };

			updatedItems[0][`label${id}`] = newValue;
			console.log("updatedItems", updatedItems);
			return {
					...prevFormData,
					[key]: updatedItems,
				};
		}); */

			

		// Aggiorna formData con lo stesso approccio immutabile
		setFormData((prevFormData) => {
			const updatedItems = prevFormData[key].map((item, idx) => {
				if (idx === 0) {
					return { ...item, [`label${id}`]: newValue };
				}
				return item;
			});

			return { ...prevFormData, [key]: updatedItems };
		});



		
		//setSelectedBrand(prevSelectedBrand => [...prevSelectedBrand]);
		//console.log("selectedBrand", selectedBrand);
	};

	const addBrand = (e) => {
		e.preventDefault();
		const nItem = Object.keys(selectedBrand).length;
		console.log("nItem Brand", nItem);
		const nArray = specialBrandData.length;

		if (nItem === 0) {
			const newLabels = Array.from({ length: nArray }, (_, index) => ({
				[`label${index + 1}`]: "",
			})).reduce((acc, curr) => ({ ...acc, ...curr }), {});

			setSelectedBrand(newLabels); // Aggiorna lo stato con le nuove etichette inizializzate
			console.log("newLabels", newLabels);
			addItem("specialBrand", newLabels); // Assumi che `addItem` sia una funzione per aggiornare un altro stato o inviare dati
		}

		setShowButton4("block");
		setShowButtonAdd4("hidden");
		setBrandOption(specialBrandData);
	};

	const deleteBrand = (e, index) => {
		e.preventDefault(); //gestisce il click del pulsante
		deleteItem("specialBrand", index);
		setShowButton4("");
		setBrandOption([]);
	};

	//=== Availability handle ===
	const handleAvailabilityChange = (event, id, key) => {
		const { checked, value } = event.target;
		const newValue = checked ? value : "";

		// Crea un nuovo array con modifiche senza mutare lo stato originale
		const newSelectedAvailability = selectedAvailability.map(
			(item, index) => {
				if (index === 0) {
					// Assumendo che stai modificando il primo elemento come nel tuo codice
					return { ...item, [`label${id}`]: newValue }; // Crea un nuovo oggetto con la proprietà aggiornata
				}
				return item; // Restituisci gli elementi non modificati così come sono
			}
		);

		// Ora imposta il nuovo array nello stato
		setSelectedAvailability(newSelectedAvailability);

		setFormData((prevFormData) => {
			const updatedItems = prevFormData[key].map((item, idx) => {
				if (idx === 0) {
					// Allo stesso modo, aggiorna il primo elemento
					return { ...item, [`label${id}`]: newValue };
				}
				return item;
			});

			return { ...prevFormData, [key]: updatedItems };
		});
	};

	// Aggiunge il pannello Disponibilità
	const addAvailability = (e) => {
		e.preventDefault();
		const nItem = selectedAvailability.length;

		if (nItem === 0) {
			const initLabels = Array.from({ length: 11 }, (_, index) => ({
				[`label${index}`]: "",
			}));
			setSelectedAvailability(initLabels);
			console.log("selectedAvailability-addItem", selectedAvailability);

			const newLabels = {};
			for (let i = 0; i < AvailabilityOption_def.length; i++) {
				newLabels[`label${i}`] = "";
			}
			addItem("availability", newLabels);
			console.log("addItem");
		}

		setShowButton3("block");
		setShowButtonAdd3("hidden");
		setAvailabilityOption(AvailabilityOption_def);
	};

	// Cancella il pannello disponibilità
	const deleteAvailability = (e, index) => {
		e.preventDefault(); //gestisce il click del pulsante
		deleteItem("availability", index);
		setShowButton3("");
		setAvailabilityOption([]);
	};

	// === Funzioni per i campi del form ===
	const handleInputChange = (e) => {
		let { name, value } = e.target;
		if (name === "state") {
			value = value.toUpperCase().slice(0, 2); // Converte in maiuscolo e limita a 2 caratteri
		}
		setFormData({ ...formData, [name]: value });
	};

	// Funzione telefono
	const handlePhoneChange = (phone) => {
		//setValue(phone);
		// Qui puoi impostare il valore nel tuo stato del form, ad esempio:
		setFormData({ ...formData, cellPhone: phone });
	};

	// Funzione caricamento foto
	const handleFileInputChange = async (event) => {
		const file = event.target.files[0];
		const data = await uploadImageToCloudinary(file);

		setSelectedFile(data.url);
		setFormData({ ...formData, photo: data.url });
	};

	//Abbonamento
	const updateCustomerHandler = async () => {
		const customerId = doctorData.stripeId.toString();

		const url = `${BASE_URL}/subscribes/customer-portal/${customerId}`;
		//console.log("URL di Fetch:", url);

		try {
			const response = await fetch(url);
			const data = await response.json();

			window.location.href = data; // Reindirizza l'utente all'URL del Billing Portal
			//console.log("Dati della Risposta:", data);
		} catch (error) {
			console.error("Errore nel recuperare i dati della sessione:", error);
		}
	};

	// Assicurazioni
	const handleInsuranceChange = (e) => {
		setNewInsurance(e.target.value);
	};

	const addInsurance = (event) => {
		event.preventDefault(); // Previene il comportamento di default
		console.log("newInsurance", newInsurance);
		if (newInsurance && !formData.healtInsurance.includes(newInsurance)) {
			setFormData({
				...formData,
				healtInsurance: [...formData.healtInsurance, newInsurance],
			});
			console.log("*-- Resetta newInsurance")
			setNewInsurance(""); // Reset the input after adding
			console.log("Current insurance list:", [...formData.healtInsurance, newInsurance]);  // Log dopo l'aggiunta
		}
	};

	const removeInsurance = (insurance) => {
		setFormData({
			...formData,
			healtInsurance: formData.healtInsurance.filter(
				(item) => item !== insurance
			),
		});
	};

	// === Pulsante UPDATE ===
	const updateDoctorHandler = async (e) => {
		e.preventDefault();

		// Mappa dei campi obbligatori con i loro messaggi di errore
		/* const requiredFields = {
			clinicName: "Il campo Intestazione Studio è obbligatorio.",
			name: "Il campo Nome è obbligatorio.",
			surname: "Il campo Cognome è obbligatorio.",
			phone: "Il campo Telefono è obbligatorio.",
			cellPhone: "Il campo Cellulare è obbligatorio.",
			city: "Il campo Città è obbligatorio.",
			state: "Il campo Provincia è obbligatorio.",
			cap: "Il campo CAP è obbligatorio.",
			street: "Il campo Indirizzo è obbligatorio.",
			patientType: "Il campo Clientela typo è obbligatorio.",
			specialization: "Il campo Specializzazione Studio è obbligatorio",
			consent: "Devi accettare il trattamento dei dati per procedere.",
		};

		// Verifica inserimento dati obbligatori
		for (const [field, errorMessage] of Object.entries(requiredFields)) {
			// Controlla se il campo è consent e verifica che sia true
			if (field === "consent" && !formData.consent) {
				toast.error(errorMessage);
				return; // Interrompe l'esecuzione della funzione se il consenso non è stato dato
			} else if (field !== "consent" && !formData[field]) {
				// Controlla gli altri campi
				toast.error(errorMessage);
				return; // Interrompe l'esecuzione della funzione se un campo obbligatorio è vuoto
			}
		} */

		// Conversione di patientType in priceRange
		const patientTypeToPriceRange = {
			economica: 1,
			standard: 2,
			premium: 3,
		};

		// Calcola priceRange in base a patientType
		const calculatedPriceRange =
			patientTypeToPriceRange[formData.patientType] || formData.priceRange; // Usa il valore esistente di priceRange come fallback

		// Prepara i dati del form con il priceRange aggiornato
		const updatedFormData = {
			...formData,
			priceRange: calculatedPriceRange,
			id: doctorId, //  doctorData._id Assicurati che doctorData._id non sia undefined
			//
		};

		try {
			const result = await updateDoctor(updatedFormData).unwrap();
			console.log("Trying to show success toast");
			toast.success("Dottore aggiornato con successo!");
			console.log("Success toast should be shown");
		} catch (err) {
			console.log("Trying to show error toast");
			toast.error(`Aggiornamento fallito: ${err.message}`);
			console.log("Error toast should be shown");
		}
	};

	// Mostra un loader finché i dati non sono completamente caricati
	if (!allDataLoaded) {
		return <CircularProgress />; // Feedback visivo durante il caricamento
	}

	return (
		<>
		{allDataLoaded ? (
		<div>
			<h2 className="text-gray-300 font-bold text-[24px] leading-9 mb-4">
				Il mio Profilo {doctorData.profileLevel}
			</h2>
			{/* Scadenza abbonamento */}
			<span className="bg-[#CCF0F3] text-irisBlueColor py-1 px-4 lg:py-2 lg:px-6 rounded text-[12px] leading-4 lg:text-[16px] lg:leading-7 font-[600]">
				Il tuo abbonamento scade il
				<b> {formatDate(doctorData.subscriptionEndDate)} </b>[
				<a
					href="#"
					onClick={updateCustomerHandler}
					className="text-blue-500 hover:text-blue-800"
				>
					Vedi Abbonamento
				</a>
				]
			</span>

			<form className="mt-10">
				{/* === clinicName fields === */}
				<div className="mb-5">
					<p className="form__label">Intestazione Studio*</p>
					<input
						type="text"
						name="clinicName"
						value={formData.clinicName}
						onChange={handleInputChange}
						placeholder="Nome  che appare in elenco"
						className="form__input"
					/>
				</div>
				{/* === name & surname fields === */}
				<div className="grid grid-cols-1 md:grid-cols-2 gap-1">
					<div className="mb-5">
						<p className="form__label">Nome user*</p>
						<input
							type="text"
							name="name"
							value={formData.name}
							onChange={handleInputChange}
							placeholder="Nome"
							className="form__input"
						/>
					</div>
					<div className="mb-5">
						<p className="form__label">Cognome user*</p>
						<input
							type="text"
							name="surname"
							value={formData.surname}
							onChange={handleInputChange}
							placeholder="Cognome"
							className="form__input"
						/>
					</div>
				</div>
				{/* === email, password  fields === */}
				<div className="grid grid-cols-1 md:grid-cols-2 gap-1">
					<div className="mb-5">
						<p className="form__label">Email/Login</p>
						<input
							type="email"
							//readOnly
							value={formData.email}
							name="email"
							onChange={handleInputChange}
							placeholder="Email da usare anche come login"
							className="form__input"
							required
							//aria-readonly
						/>
					</div>
					<div className="mb-5 relative">
						<p className="form__label">Nuova Password</p>
						<input
							type={showPassword ? "text" : "password"}
							value={formData.password}
							name="password"
							onChange={handleInputChange}
							placeholder="Modifica Password"
							className="form__input"
							//required
						/>
						<button
							type="button"
							className="absolute top-1/2 right-3 transform -translate-y-1/2 text-black"
							onClick={togglePasswordVisibility}
						>
							<FontAwesomeIcon
								icon={showPassword ? faEyeSlash : faEye}
							/>
						</button>
					</div>
				</div>
				{/* === email studio === */}
				<div className="mb-5">
					<p className="form__label">Email studio</p>
					<input
						type="text"
						name="clinicEmail"
						value={formData.clinicEmail}
						onChange={handleInputChange}
						placeholder="email studio"
						className="form__input"
					/>
				</div>
				{/* === city, cap, state  fields === */}
				<div className="grid grid-cols-1 md:grid-cols-3 gap-1">
					<div className="mb-5">
						<p className="form__label">Città*</p>
						<input
							type="text"
							name="city"
							value={formData.city}
							onChange={handleInputChange}
							placeholder="Città/Comune"
							className="form__input"
						/>
					</div>
					<div className="mb-5">
						<p className="form__label">CAP*</p>
						<input
							type="text"
							name="cap"
							value={formData.cap}
							onChange={handleInputChange}
							placeholder="CAP"
							className="form__input"
						/>
					</div>
					<div className="mb-5">
						<p className="form__label">Provincia (sigla)*</p>
						<input
							type="text"
							name="state"
							value={formData.state}
							onChange={handleInputChange}
							placeholder="sigla"
							maxLength="2" // Limita l'input a 2 caratteri
							className="form__input"
						/>
					</div>
				</div>
				{/* === street  fields === */}
				<div className="mb-5">
					<p className="form__label">Indirizzo*</p>
					<input
						type="text"
						name="street"
						value={formData.street}
						onChange={handleInputChange}
						placeholder="Via, numero, Località"
						className="form__input"
					/>
				</div>
				{/* === phone, cell phone  fields === */}
				<div className="grid grid-cols-1 md:grid-cols-2 gap-1">
					<div className="mb-5">
						<p className="form__label">Telefono prenotazione*</p>
						<input
							type="text"
							value={formData.phone}
							onChange={handleInputChange}
							name="phone"
							placeholder="Numero"
							className="form__input"
						/>
					</div>
					<div className="mb-5">
						<p className="form__label">
							Cellulare Studio* (non visibile al paziente)
						</p>
						<PhoneInput
							international
							defaultCountry="IT"
							value={formData.cellPhone}
							onChange={handlePhoneChange}
							className="form__input"
						/>
					</div>
				</div>
				{/* === biography fields === */}
				<div className="mb-5">
					<label className="form__label" htmlFor="biography">
						Presentazione
					</label>
					<textarea
						id="biography"
						value={formData.biography}
						onChange={handleInputChange}
						name="biography"
						maxLength={2000}
						placeholder="Presentazione breve di max 2.000 caratteri"
						className="form__input"
						rows={4} // specifica il numero di righe desiderato
					/>
				</div>

				{/* === healtInsurance, specialization, priceRange  fields === */}
				<div className="mb-5">
					<div className="grid grid-cols-3 gap-5 mb-[30px]">
					<div>
							<p className="form__label">Assicurazioni in conv.</p>
							<div className="input-group relative flex flex-col items-end">
								<div className="absolute translate-y-1/2 self-end mr-2">
									<button
										onClick={addInsurance}
										className="text-right  text-blue-600"
									>
										<FontAwesomeIcon icon={faPlusSquare} size="lg" />
									</button>
								</div>
								<input
									type="text"
									placeholder="Assicurazione"
									value={newInsurance}
									onChange={handleInsuranceChange}
									className="form__input w-full"
								/>
							</div>
							<ul>
								{formData.healtInsurance.map((insurance, index) => (
									<li
										key={index}
										className="flex justify-between items-center mt-2"
									>
										{insurance}
										<button
											onClick={() => removeInsurance(insurance)}
											className="ml-4"
										>
											<FontAwesomeIcon icon={faMinusSquare} />
										</button>
									</li>
								))}
							</ul>
						</div>
						<div>
							<p className="form__label">Eccellenza Studio in*</p>
							<select
								name="specialization"
								value={formData.specialization}
								onChange={handleInputChange}
								className="form__input py-3.5"
							>
								<option value="">Seleziona</option>
								<option value="Igiene Orale">Igiene Orale</option>
								<option value="Odontoiatria Generale">
									Odontoiatria Generale
								</option>
								<option value="Conservativa">Conservativa</option>
								<option value="Odontoiatria Pediatrica">
									Odontoiatria Pediatrica
								</option>
								<option value="Chirurgia Orale">Chirurgia Orale</option>
								<option value="Protesi Dentaria">
									Protesi Dentaria
								</option>
								<option value="Parodontologia">Parodontologia</option>
								<option value="Odontoiatria Estetica">
									Odontoiatria Estetica
								</option>
								<option value="Ortodonzia">Ortodonzia</option>
								<option value="Implantologia">Implantologia</option>
							</select>
						</div>
						<div>
							<p className="form__label">Clientela tipo*</p>
							<select
								name="patientType"
								value={formData.patientType}
								className="form__input"
								onChange={handleInputChange}
							>
								<option value="">Seleziona</option>
								<option value="economica">economica</option>
								<option value="standard">standard</option>
								<option value="premium">premium</option>
							</select>
						</div>
					</div>
				</div>

				{/* === healthDirector, medicalRegister fields === */}
				<div className="grid grid-cols-1 md:grid-cols-2 gap-1">
					<div className="mb-5">
						<p className="form__label">Direttore Sanitario</p>
						<input
							type="text"
							name="healthDirector"
							value={formData.healthDirector}
							onChange={handleInputChange}
							placeholder="Nome e Cognome"
							className="form__input"
						/>
					</div>
					<div className="mb-5">
						<p className="form__label">N. Albo</p>
						<input
							type="text"
							name="medicalRegister"
							value={formData.medicalRegister}
							onChange={handleInputChange}
							placeholder="Numero Albo del direttore Sanitario"
							className="form__input"
						/>
					</div>
				</div>
				{/* === performance field === */}
				<div className="mb-5">
					<p className="form__label">
						Altre specializzazioni studio* (Max 3 per utenti Regular)
					</p>
					<div>
						<div className="border-2 border-blue-500 rounded-lg bg-white p-4 text-black">
							<ul className="grid grid-cols-1 md:grid-cols-3 gap-1">
								{PerformanceOption?.map((option) => (
									<li key={option.idPerformance}>
										<label className="flex-center items-center">
											<input
												className="mr-2"
												type="checkbox"
												name={`label${option.idPerformance}`}
												value={option.label}
												checked={selectedPerformance[0][`label${option.idPerformance}`] ?? false}
												/* {...console.log("checked", selectedPerformance[0][`label${option.idPerformance}`] ?? false)} */
												onChange={(e) => {
													// Controlla se il numero di prestazioni selezionate è inferiore a tre
													/* if (selectedPerformanceCount < 3) { */
													if (
														!isRegularProfile ||
														!isMaxPerformanceSelected ||
														selectedPerformance[0][
															`label${option.idPerformance}`
														]
													) {
														handlePerformanceChange(
															e,
															option.idPerformance,
															"performance"
														);
													}
												}}
											/>
											{option.label}
										</label>
									</li>
								))}
							</ul>
						</div>
						{/* <p className="mt-2 mb-5">Prestazioni selezionate: {selectedPerformance.join(', ')}</p> */}
						{/* <button
           className={`${showButton ? showButton : 'hidden'} bg-red-600 p-2 rounded-full text-white text-[18px] mt-2 mb-[30px]`}
           onClick={e => deletePerformance(e, 0)}
         >
           <AiOutlineDelete />
         </button> */}
					</div>

					<button
						onClick={addPerformance}
						className={`${showButtonAdd} bg-[#000] py-2 px-5 rounded text-white`}
					>
						Aggiungi Specializzazioni
					</button>
				</div>
				{/* === services field  === */}
				<div className="mb-5">
					<p className="form__label">Servizi*</p>
					<div>
						<div className="border-2 border-blue-500 rounded-lg bg-white p-4  text-black">
							<ul className="grid grid-cols-1 md:grid-cols-3 gap-1">
								{serviceOption?.map((option) => (
									<li key={option.idService}>
										<label className="flex-center items-center">
											<input
												className="mr-2"
												type="checkbox"
												name={`label${option.idService}`}
												value={option.label}
												checked={selectedService[0][`label${option.idService}`] ?? false}
												onChange={(e) =>
													handleServiceChange(
														e,
														option.idService,
														"services"
													)
												}
											/>
											{option.label}
										</label>
									</li>
								))}
							</ul>
						</div>
						{/* <button
           className={`${showButton2 ? showButton2 : 'hidden'} bg-red-600 p-2 rounded-full text-white text-[18px] mt-2 mb-[30px]`}
           onClick={e => deleteService(e, 0)}
         >
           <AiOutlineDelete />
         </button> */}
					</div>

					<button
						onClick={addService}
						className={`${showButtonAdd2} bg-[#000] py-2 px-5 rounded text-white`}
					>
						Aggiungi Servizi
					</button>
				</div>

				{/* === Special Brand field  === */}
				<div className="mb-5">
					<p className="form__label">Servizi Speciali</p>
					<div>
						<div className="border-2 border-borderColor rounded-lg bg-white p-4 text-black">
							<ul className="grid grid-cols-1 md:grid-cols-3 gap-1">
								{brandOption?.map((option) => (
									<li key={option.idService}>
										<label className="flex-center items-center">
											<input
												className="mr-2"
												type="checkbox"
												name={`label${option.idService}`}
												value={`${option.label} (${option.dentalTreatment})`}
												/* checked={selectedBrand[0][`label${option.idService}`] ?? false} */
												checked={
													selectedBrand[
														`label${option.idService}`
													] || false
												}
												/*  {...console.log("checked", selectedService[0][`label${option.idService}`] ?? false)}  */
												onChange={(e) =>
													handleBrandChange(
														e,
														option.idService,
														"specialBrand"
													)
												}
											/>
											{option.label} ({option.dentalTreatment})
										</label>
									</li>
								))}
							</ul>
						</div>
						<button
							className={`${
								showButton4 ? showButton4 : "hidden"
							} bg-red-600 p-2 rounded-full text-white text-[18px] mt-2 mb-[30px]`}
							onClick={(e) => deleteBrand(e, 0)}
						>
							<AiOutlineDelete />
						</button>
					</div>

					<button
						onClick={addBrand}
						className={`${showButtonAdd4} bg-[#000] py-2 px-5 rounded text-white`}
					>
						Aggiungi Servizi Speciali
					</button>
				</div>

				{/* === availability field  === */}
				<div className="mb-5">
					<p className="form__label">Disponibilità*</p>
					<div>
						<div className="border-2 border-blue-500 rounded-lg bg-white p-4  text-black">
							<ul className="grid grid-cols-1 md:grid-cols-3 gap-1">
								{availabilityOption?.map((option) => (
									<li key={option.id}>
										<label className="flex-center items-center">
											<input
												className="mr-2"
												type="checkbox"
												name={`label${option.id}`}
												value={option.label}
												checked={
													selectedAvailability[0][
														`label${option.id}`
													] ?? false
												}
												/* {...console.log("checked", selectedAvailability[0][`label${option.id}`] ?? false)} */
												onChange={(e) =>
													handleAvailabilityChange(
														e,
														option.id,
														"availability"
													)
												}
											/>
											{option.label}
										</label>
									</li>
								))}
							</ul>
						</div>
						{/* <p className="mt-2 mb-5">Prestazioni selezionate: {selectedPerformance.join(', ')}</p> */}
						{/* <button
           className={`${showButton3 ? showButton3 : 'hidden'} bg-red-600 p-2 rounded-full text-white text-[18px] mt-2 mb-[30px]`}
           onClick={e => deleteAvailability(e, 0)}
         >
           <AiOutlineDelete />
         </button> */}
					</div>

					<button
						onClick={addAvailability}
						className={`${showButtonAdd3} bg-[#000] py-2 px-5 rounded text-white`}
					>
						Aggiungi Disponibilità
					</button>
				</div>

				{/* === photo + sponsor fields === */}
				<div className="grid grid-cols-1 md:grid-cols-2 gap-1">
					{/* === sponsor field === */}
					<div className="mb-5 flex items-center gap-3">
						{formData.photo && (
							<figure className="w-[60px] h-[60px] rounded-full border-2 border-solid border-[#0067FF] flex items-center justify-center">
								<img
									src={formData.photo}
									alt="Preview"
									className="w-full rounded-full"
								/>
							</figure>
						)}
						<div className="relative inline-block w-[130px] h-[50px]">
							<input
								className="custom-file-input absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
								id="customFile"
								name="photo"
								type="file"
								accept=".jpg,.png,.jpeg"
								placeholder="Upload Profile"
								onChange={handleFileInputChange}
							/>

							<label
								className="custom-file-label absolute top-0 left-0 w-full h-full flex items-center px-[0.75rem] py-[0.375rem] text-[15px] leading-6 overflow-hidden bg-[#0066ff46] text-headingColor font-semibold rounded-lg truncate cursor-pointer"
								htmlFor="customFile"
							>
								{selectedFile ? selectedFile.name : "Upload Photo"}
							</label>
						</div>
					</div>

					{/* === sponsor field === */}
					{/* <div className="mb-5">
         <p className="form__label">Sponsor</p>
         <input
           type="text"
           name="sponsor"
           value={formData.sponsor}
           onChange={handleInputChange}
           placeholder="Sponsor"
           className="form__input"
         />
       </div> */}

					{/* === consent field === */}
					<div className="mb-5 flex items-start">
						<input
							className="form-check-input mr-2 mt-1"
							type="checkbox"
							id="consent"
							name="consent"
							checked={formData.consent}
							onChange={handleInputCheckbox}
						/>
						<label
							htmlFor="consent"
							className="form-check-label text-[10px]"
						>
							Acconsento al trattamento dei miei dati personali in
							conformità con il GDPR per le finalità e secondo le
							modalità specificate nella{" "}
							<Iubenda id={MY_POLICY_ID} type="privacy">
								[Politica sulla Privacy]
							</Iubenda>
							. Ho letto e accetto i{" "}
							<Iubenda id={MY_POLICY_ID} type="terms-and-conditions">
								[Termini e le Condizioni]
							</Iubenda>
							.
						</label>
					</div>
				</div>

				<div className="mt-7">
					<button
						type="submit"
						onClick={updateDoctorHandler}
						className="w-full bg-[#0067FF] text-white py-3 px-4 rounded-lg text-[18px] leading-[30px]"
					>
						Update Profilo
					</button>
				</div>
			</form>
		</div>
		) : (
			<div>Caricamento in corso...</div>
		)}
		</>
	);
};

export default Profile;
