import React from "react";
import { Box, useTheme } from "@mui/material";
import { useGetGeographyQuery } from "state/api";
import { ResponsiveChoropleth } from "@nivo/geo";
import { geoData } from "state/geoData";
import Header from "components/Header";

const Geography = () => {
  const theme = useTheme();
  const { data } = useGetGeographyQuery();
  /* const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  }); */

 /*  useEffect(() => {
    const handleResize = () => {
      console.log("*-- resize")
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); */

	const handleMapClick = (event) => {
		// Azioni da eseguire quando viene fatto clic sulla mappa
		console.log("Area cliccata:", event.properties.name);
		// Aggiungi qui altre azioni che desideri eseguire
	};

	return (
		<Box className="m-6">
			<Header
				title="Distribuzione geografica"
				subtitle="Numero dentisti per provincia."
			/>
			<Box className="mt-10 border-2 border-white rounded-xl flex flex-col justify-between overflow-x-scroll overflow-y-scroll w-[calc(100vw-20rem)] h-[calc(100vh-15rem)]">
				<Box
					mt="40px"
					//border={`1px solid ${theme.palette.secondary[200]}`}
					//borderRadius="8px"
					display="flex"
					//flexDirection="column"
          flexDirection="row"
					justifyContent="space-between"
					height={["100%", "100vh"]}
          width={["100%", "100vw"]}
					//overflowX="scroll" // Utilizza overflowY invece di overflow
          //overflow="scroll"
				>
					{data ? (
						<ResponsiveChoropleth
							data={data}
							/* {...console.log("data", data)} */
							theme={{
								axis: {
									domain: {
										line: {
											stroke: theme.palette.secondary[200],
										},
									},
									legend: {
										text: {
											fill: theme.palette.secondary[200],
										},
									},
									ticks: {
										line: {
											stroke: theme.palette.secondary[200],
											strokeWidth: 1,
										},
										text: {
											fill: theme.palette.secondary[200],
										},
									},
								},
								legends: {
									text: {
										fill: theme.palette.secondary[200],
									},
								},
								tooltip: {
									container: {
										color: theme.palette.primary.main,
									},
								},
							}}
							//l'array di oggetti che rappresenta le caratteristiche geografiche da visualizzare sulla mappa
							features={geoData.features}
							/* {...console.log("geoData", geoData.features)} */
							margin={{ top: 0, right: 0, bottom: 0, left: -50 }}
							colors={[
								"#d6c1c1",
								"#ff8e44",
								"#ff644e",
								"#ff1f5f",
								"#b11587",
								"#891b97",
								"#6723cf",
								"#3262f3",
								"#3782f0",
								"#2ea9e2",
								"#2eccd3",
								"#3bf4d2",
							]} // Personalizza i colori della mappa
							domain={[0, 60]} //valori mini/massimo in legenda
							unknownColor="#666666" // Colore per i dati sconosciuti
							label="properties.name"
							//{...console.log("properties.name", properties.name)}
							valueFormat=".2s" //valore intero .2s con decimali
							projectionScale={3000} //grandezza oggetti in mappa se aumenta si spostano verso l'alto
							// projectionTranslation={[0.45, 0.6]}
							projectionTranslation={[-0.1, 3]} //primo valore + spostamento verso sinistra, secondo valore + spostamento verso il basso
							projectionRotation={[0, 0, 0]}
							borderWidth={1.5}
							borderColor="#0207c3"
							onClick={(event, data) => handleMapClick(event)}
							//tooltip={e=>{}}
							defs={[
								{
									id: "dots",
									type: "patternDots",
									background: "inherit",
									color: "#38bcb2", //verde menta
									size: 4,
									padding: 1,
									stagger: true,
								},
								{
									id: "lines",
									type: "patternLines",
									background: "inherit",
									color: "#eed312", //giallo
									rotation: -45,
									lineWidth: 6,
									spacing: 10,
								},
								{
									id: "gradient",
									type: "linearGradient",
									colors: [
										{
											offset: 0,
											color: "#000",
										},
										{
											offset: 100,
											/* color: 'inherit' */
											color: "ff9c00", //arancio
										},
									],
								},
							]}
							legends={[
								{
									anchor: "bottom-right",
									direction: "column",
									justify: true,
									translateX: 0,
									// translateY: -125,
									translateY: -60, //Y position leggenda
									itemsSpacing: 2, //spazio tra le tessere
									itemWidth: 94,
									itemHeight: 18,
									itemDirection: "left-to-right",
									itemTextColor: theme.palette.secondary[200],
									itemOpacity: 0.85,
									symbolSize: 18,
									effects: [
										{
											on: "hover",
											style: {
												/* itemTextColor: theme.palette.background.alt, */
												itemTextColor: "#3bf4d2",
												itemOpacity: 1,
											},
										},
									],
								},
							]}
						/>
					) : (
						<>Loading...</>
					)}
				</Box>
			</Box>
		</Box>
	);
};

export default Geography;
