// api.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

//console.log("env", process.env.REACT_APP_BASE_URL);

// Setup the base API
export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  tagTypes: [
    "User", "Dentisti", "Customers", "Transactions", "Eventi", "Geografia",
    "Geografia1", "Sales", "Dashboard", "Admins", "Appuntamenti",
  ],
  endpoints: (builder) => ({
    // Regular endpoints
    getDashboard: builder.query({
      query: () => "general/dashboard",
      providesTags: ["Dashboard"],
     }),
    getUser: builder.query({
      query: (id) => `general/user/${id}`,
      providesTags: ["User"],
    }),
    getAdmins: builder.query({
      query: () => "management/admins",
      providesTags: ["Admins"],
    }),
    getDentists: builder.query({
      query: () => "client/dentisti",
      providesTags: ["Dentisti"],
    }),
    getDentistById: builder.query({
      query: (id) => `client/dentisti/${id}`,
      providesTags: ['Dentisti'],
    }),
    getCustomers: builder.query({
      query: () => "client/customers",
      providesTags: ["Customers"],
    }),
    getRegularDoctor: builder.query({
      query: () => "sales/regular",
      providesTags: ["Sales"],
    }),
    getPremiumDoctor: builder.query({
      query: () => "sales/premium",
      providesTags: ["Sales"],
    }),
    getSales: builder.query({
      query: () => "sales/sales",
      providesTags: ["Sales"],
    }),
    getSponsor: builder.query({
      query: () => "sales/sponsor",
      providesTags: ["Sales"],
    }),
    getTransactions: builder.query({
      query: ({ page, pageSize, sort, search }) => ({
        url: "client/transactions",
        method: "GET",
        params: { page, pageSize, sort, search },
      }),
      providesTags: ["Transactions"],
    }),
    getEventi: builder.query({
      query: ({ page, pageSize, sort, search }) => ({
        url: "client/eventi",
        method: "GET",
        params: { page, pageSize, sort, search },
      }),
      providesTags: ["Eventi"],
    }),
    getGeography: builder.query({
      query: () => "client/geografia",
      providesTags: ["Geografia"],
    }),
    getGeography1: builder.query({
      query: () => "client/geografia1",
      providesTags: ["Geografia1"],
    }),
    getPerformance: builder.query({
      query: () => `client/performance`,
      providesTags: ['Dentisti'],
    }),
    getServices: builder.query({
      query: () => `client/services`,
      providesTags: ['Dentisti'],
    }),
    getSpecialBrand: builder.query({
      query: () => `client/special-brand`,
      providesTags: ['Dentisti'],
    }),
    getAppointment: builder.query({
      query: () => `client/appointment`,
      providesTags: ['Appuntamenti'],
    }),
    getAppointmentsByDoctorId: builder.query({
      query: (doctorId) => `client/appointment/${doctorId}`,
      providesTags: ['Appuntamenti'],
    }),

    // Lazy Query for getting user by email 
    getUserByEmail: builder.query({
      query: ({email, password}) => `management/login?email=${email}&password=${password}`,
      providesTags: ["Admins"],
    }),
    

    // Mutation example for updating a doctor
    updateDoctor: builder.mutation({
      query: (doctorData) => ({
        url: `client/doctors/${doctorData._id}`,
        method: 'PUT',
        body: doctorData,
        headers: {
            'Content-Type': 'application/json',
        },
      }),
      invalidatesTags: ['Dentisti'],
    }),
  }),
});

// Export hooks for usage in functional components, include lazy queries
export const {
  useGetDashboardQuery,
  useGetUserQuery,
  useGetDentistsQuery,
  useGetDentistByIdQuery,
  useGetCustomersQuery,
  useGetTransactionsQuery,
  useGetEventiQuery,
  useGetGeographyQuery,
  useGetGeography1Query,
  useGetPerformanceQuery,
  useGetServicesQuery,
  useGetSpecialBrandQuery,
  useGetRegularDoctorQuery,
  useGetPremiumDoctorQuery,
  useGetSalesQuery,
  useGetSponsorQuery,
  useGetAdminsQuery,
  useGetAppointmentQuery,
  useGetAppointmentsByDoctorIdQuery,
  useLazyGetUserByEmailQuery,  // Exporting the lazy query
  useUpdateDoctorMutation
} = api;
