// localhost/ host remoto per build


let BASE_URL;
let SITE_URL;
let PRO_URL;

if (process.env.NODE_ENV === "development") {
  BASE_URL = "http://localhost:5001/api/v1";
  //BASE_URL = "https://dashboard-ids.azurewebsites.net";
  SITE_URL = "http://localhost:3002";
  PRO_URL = "http://localhost:5173";
} else if (process.env.NODE_ENV === "production") {
  //BASE_URL = "https://dashboard-ids.azurewebsites.net";
  BASE_URL = 'https://server-admin.ildentistagiusto.com';
  SITE_URL = "https://ildentistagiusto.com";
  PRO_URL = "https://pro.ildentistagiusto.com";
}

export const token = localStorage.getItem("token");
export const user = localStorage.getItem("user");
export const role = localStorage.getItem("role");

export { BASE_URL, SITE_URL, PRO_URL };

// Iubenda
export const MY_POLICY_ID = "36198449";

