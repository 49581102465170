// Navbar.jsx
/* eslint-disable no-unused-vars */
import React, { useState, useContext } from "react";
import {
  LightModeOutlined,
  DarkModeOutlined,
  Menu as MenuIcon,
  Search,
  SettingsOutlined,
  ArrowDropDownOutlined,
  Close as CloseIcon,
} from "@mui/icons-material";
//import FlexBetween from "components/FlexBetween";
import { useDispatch } from "react-redux";
import { setMode } from "state";
import profileImage from "assets/profile.jpeg";
import {
  AppBar,
  Button,
  Box,
  Typography,
  IconButton,
  InputBase,
  Toolbar,
  Menu,
  MenuItem,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import Login from "components/Login";
import { AuthContext } from "context/AuthContext"; // Assicurati che il percorso sia corretto
import { toast } from "react-toastify";


const Navbar = ({ user, isSidebarOpen, setIsSidebarOpen }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  //const { dispatch: authDispatch } = useContext(AuthContext);  // Usando useContext per accedere a dispatch di AuthContext
  const { dispatch: authDispatch, user: authUser } = useContext(AuthContext); // usare authUser direttamente


  const [anchorEl, setAnchorEl] = useState(null);
  const [loginOpen, setLoginOpen] = useState(false);
  //const isLoggedIn = Boolean(user && user.name); // Assume user object has name when logged in
  const isLoggedIn = Boolean(authUser && authUser.name); // usare authUser qui


  const handleLoginClick = () => {
    setLoginOpen(true);
  };

  const handleCloseLogin = () => {
    setLoginOpen(false);
  };

  // LOGOUT
  const handleLogout = () => {
    // Aggiungi qui la logica per effettuare il logout
    authDispatch({ type: "LOGOUT" });
    handleClose(); // Chiudi il menu
    toast.info("Logout effettuato con successo"); // Mostra un messaggio di successo
  };

  // LOGIN
  const handleProfileClick = (event) => {
    if (!isLoggedIn) {
      handleLoginClick();
    } else {
      //setAnchorEl(event.currentTarget);
      handleClick(event);
    }
  };

  const isOpen = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

    // Callback per chiudere il popup di login
    const handleLoginSuccess = () => {
      setLoginOpen(false);
    };

  return (
    <AppBar className="static bg-transparent shadow-none">
      <Toolbar id="toolBar" className="ml-60 justify-between">
        {/* LEFT SIDE */}
        <div id="leftSide" className="flex items-center">
          <div>
            <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
              <MenuIcon />
            </IconButton>
          </div>
          <div className="flex items-center bg-[color] rounded-lg gap-12 p-1 pr-6"> 
         {/*  <div> */}
            <InputBase placeholder="Search..." />
            <IconButton>
              <Search />
            </IconButton>
          </div>
        </div>

        {/* RIGHT SIDE */}
        <div id="rightSide" className="flex items-center gap-6">
          <IconButton onClick={() => dispatch(setMode())}>
            {theme.palette.mode === "dark" ? (
              <DarkModeOutlined sx={{ fontSize: "25px" }} />
            ) : (
              <LightModeOutlined sx={{ fontSize: "25px" }} />
            )}
          </IconButton>
          <IconButton>
            <SettingsOutlined sx={{ fontSize: "25px" }} />
          </IconButton>

          {/* LOGIN/LOGOUT */} 
          <Button sx={{ border: 1, borderColor: 'rgb(127, 127, 127)', marginTop: '5px', color: 'rgb(127, 127, 127)' }} onClick={handleProfileClick}>
            {/* <Typography>{isLoggedIn ? user.name : "Login"}</Typography> */}
            <Typography>{isLoggedIn ? authUser.name : "Login"}</Typography>
            {isLoggedIn && <ArrowDropDownOutlined />}
          </Button>
          
          {isLoggedIn && (
            <Menu anchorEl={anchorEl} open={isOpen} onClose={handleClose}>
              <MenuItem onClick={handleLogout}>Log Out</MenuItem>
            </Menu>
          )}
          <Dialog open={loginOpen} onClose={handleCloseLogin}>
            <DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleCloseLogin}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                  backgroundColor: '#fff',  // Imposta il background bianco
                  borderRadius: '50%',      // Rende l'icona circolare
                  border: '1px solid',      // Aggiunge un bordo sottile
                  borderColor: (theme) => theme.palette.grey[300],  // Colore del bordo
                  '&:hover': {              // Stili per lo stato hover
                    backgroundColor: (theme) => theme.palette.primary[600],  // Leggermente più scuro quando il mouse è sopra
                  }
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              <Login onSuccess={handleLoginSuccess} />
            </DialogContent>
          </Dialog>          

        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
