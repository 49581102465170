import React from "react";

const Home = () => {
	return (
		<section>
			<div className="px-4 mx-auto max-w-screen-md">
				<h1 className="heading text-white text-center mb-5">IDG Dashboard</h1>
			</div>
		</section>
	);
};

export default Home;
