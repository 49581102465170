// ,/scenes/appuntamenti/index.jsx
import React, { useRef, useEffect, useState } from "react";
import Header from "components/Header";
import "./index.css";
import {
	ScheduleComponent,
	Inject,
	Agenda,
	Day,
	Month,
	Week,
	WorkWeek,
	ViewsDirective,
	ViewDirective,
	TimelineViews,
	DragAndDrop,
	Resize,
	ResourcesDirective,
	ResourceDirective,
} from "@syncfusion/ej2-react-schedule";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare, faMinusSquare } from "@fortawesome/free-solid-svg-icons";
import { useGetAppointmentQuery, useGetAppointmentsByDoctorIdQuery } from "../../state/api";
import "./localIT";

const Appuntamenti = () => {
	const scheduleObj = useRef(null);
	const workDays = [1, 2, 3, 4, 5]; // Giorni lavorativi
	const [localData, setLocalData] = useState([]);
	const [newDoctorName, setNewDoctorName] = useState("");
	const [doctor, setDoctor] = useState("");
	const [doctorId, setDoctorId] = useState("");

	//const token = localStorage.getItem("token");
	const user = localStorage.getItem("user");

	useEffect(() => {
		// Verifica se user è una stringa e prova a convertirla in oggetto
		if (typeof user === "string") {
			try {
				const parsedUser = JSON.parse(user);
				setDoctor(parsedUser);
			} catch (error) {
				console.error("Error parsing user string:", error);
			}
		}
	}, []);

	useEffect(() => {
		if (doctor && doctor._id) {
			console.log("doctor._id", doctor._id);
			setDoctorId(doctor._id);
			//setDoctorName(doctor.name);
			//setDoctorSurname(doctor.surname);
		}
	}, [doctor]);

	// Loading performance
	//const { data: appointmentData } = useGetAppointmentQuery();
	const { data: appointmentData } = useGetAppointmentsByDoctorIdQuery(doctorId);
	//console.log("appointmentData", appointmentData);

	const [resourceDataSource, setResourceDataSource] = useState([
		{ Name: "Prima visita", Id: 1, Color: "#3fffa7" },
		{ Name: "Igiene dentale", Id: 2, Color: "#46daff" },
		//{ Name: "dr. James", Id: 3, Color: "#7fa900" },
	]);

	useEffect(() => {
		if (appointmentData) {
			const formattedData = appointmentData.map((appointment) => ({
				Id: appointment.Id,
				Subject: appointment.Subject,
				StartTime: new Date(appointment.StartTime),
				EndTime: new Date(appointment.EndTime),
				ResourceID: appointment.ResourceID,
			}));
			setLocalData(formattedData);
		}
	}, [appointmentData]);

	useEffect(() => {
		if (scheduleObj.current) {
			//console.log("ScheduleComponent è montato:", scheduleObj.current);
			// Ad esempio, potresti voler chiamare un metodo del componente qui.
		}
	}, []);

	const onTreeDragStop = (args) => {
		const scheduleInstance = scheduleObj.current;
		if (scheduleInstance) {
			let cellData = scheduleInstance.getCellDetails(args.target);
			let eventData = {
				Subject: args.draggedNodeData.text,
				StartTime: cellData.startTime,
				EndTime: cellData.endTime,
				IsAllDay: cellData.isAllDay,
			};
			scheduleInstance.openEditor(eventData, "Add", true);
		} else {
			console.log("Schedule instance not available");
		}
	};

	const onDragStart = (args) => {
		args.navigation.enable = true;
		console.log("Drag Variazione", localData[1].StartTime);
	};

	const onResizeStart = (args) => {
		args.scroll.enable = false;
		args.interval = 30;
	};

	const onActionBegin = async (args) => {
		//if (args.requestType === 'eventCreate' || args.requestType === 'eventChange') {
		if (args.requestType === "eventCreate") {
			const eventData = args.data instanceof Array ? args.data[0] : args.data;
			console.log("eventData", eventData);

			if (!isWorkingDay(eventData.StartTime)) {
				args.cancel = true; // Annulla la creazione o la modifica dell'evento
				alert("Non è possibile creare appuntamenti nei giorni esclusi.");
			} else {
				// Registra l'appuntamento nel database
				try {
					const response = await fetch(
						`${process.env.REACT_APP_BASE_URL}/client/appointment`,
						{
							method: "POST",
							headers: {
								"Content-Type": "application/json",
							},
							body: JSON.stringify({
								//doctorId: "65f4431f597bec386fdbb161",
								doctorId: doctorId,
								Id: eventData.Id,
								Subject: eventData.Subject,
								StartTime: eventData.StartTime,
								EndTime: eventData.EndTime,
								ResourceID: eventData.ResourceID,
								isBooked: false,
								//patientId: "",
							}),
						}
					);
					if (!response.ok) {
						throw new Error("Errore nella creazione dell'appuntamento");
					}
					console.log("Appuntamento creato con successo");
				} catch (error) {
					console.error("Errore:", error);
				}
			}
		}
      if (args.requestType === "eventChange") {
			const eventData = args.data instanceof Array ? args.data[0] : args.data;
			console.log("eventData", eventData);

			if (!isWorkingDay(eventData.StartTime)) {
				args.cancel = true; // Annulla la creazione o la modifica dell'evento
				alert("Non è possibile modificare appuntamenti nei giorni esclusi.");
			} else {
				// Modifica l'appuntamento nel database
				try {
					const response = await fetch(
						`${process.env.REACT_APP_BASE_URL}/client/appointment/${eventData.Id}`,
						{
							method: "PUT",
							headers: {
								"Content-Type": "application/json",
							},
							body: JSON.stringify({
								Subject: eventData.Subject,
								StartTime: eventData.StartTime,
								EndTime: eventData.EndTime,
								ResourceID: eventData.ResourceID,
							}),
						}
					);
					if (!response.ok) {
						throw new Error("Errore nella modifica dell'appuntamento");
					}
					console.log("Appuntamento modificato con successo");
				} catch (error) {
					console.error("Errore:", error);
				}
			}
		}
		if (args.requestType === 'eventRemove') {
			const eventData = args.data instanceof Array ? args.data[0] : args.data;
			console.log('Cancellazione di', eventData);
	
			try {
				const response = await fetch(
					`${process.env.REACT_APP_BASE_URL}/client/appointment/${eventData.Id}/${doctorId}`, 
					{
						method: 'DELETE',
						headers: {
							'Content-Type': 'application/json',
						},
				 	}
				);
				if (!response.ok) {
					throw new Error('Errore nella cancellazione dell\'appuntamento');
				 }
			  console.log('Appuntamento cancellato con successo');
			} catch (error) {
			  console.error('Errore:', error);
			  alert('Errore nella cancellazione dell\'appuntamento');
			  args.cancel = true; // Annulla l'operazione di cancellazione
			}
		 }

	};

	const onEventRendered = (args) => {
		if (args.data.Subject === "slot libero") {
			args.element.style.backgroundColor = "green";
		}
	};

	const isWorkingDay = (date) => {
		const day = date.getDay(); // Ottiene il giorno della settimana, 0 = Domenica, 1 = Lunedì, ..., 6 = Sabato
		return workDays.includes(day);
	};

	/* const addDoctor = () => {
		if (newDoctorName.trim() === "") {
			alert("Il nome del dottore non può essere vuoto.");
			return;
		}
		const newDoctor = {
			Name: `dr. ${newDoctorName}`,
			Id: resourceDataSource.length + 1,
			Color: "#" + Math.floor(Math.random() * 16777215).toString(16), // Colore casuale
		};
		setResourceDataSource([...resourceDataSource, newDoctor]);
		setNewDoctorName("");
	}; */

	/* const removeDoctor = (doctorId) => {
		const updatedResourceDataSource = resourceDataSource.filter(
			(resource) => resource.Id !== doctorId
		);
		setResourceDataSource(updatedResourceDataSource);
	}; */

	/* const onCreated = (args) => {
		console.log("*-- onCreated");
	}; */

	return (
		<div>
			<Header
				title="APPUNTAMENTI"
				subtitle="Lista appuntamenti per prestazione"
			/>
			<div className="scheduler-title-container">Programmazione Appuntamenti</div>
			<div className="scheduler-component">
				<ScheduleComponent
					//locale='it'
					ref={scheduleObj}
					currentView="Week"
					actionBegin={onActionBegin}
					eventRendered={onEventRendered}
					//created={onCreated}
					workHours={{
						highlight: true,
						start: "00:00",
						end: "24:00",
					}}
					workDays={workDays}
					timeScale={{ interval: 60, slotCount: 2 }} // Configura slot di 1 ora
					eventSettings={{ dataSource: localData }}
					dragStart={onDragStart}
					resizeStart={onResizeStart}
					selectedDate={new Date()}
					group={{ resources: ["Resources"] }}
				>
					<ViewsDirective>
						<ViewDirective
							option="Day"
							//interval={3}
							//displayName="3 Day"
							startHour="9:00"
                  	endHour="18:00"
						/>
						<ViewDirective
							option="Week"
							interval={1}
							isSelected={true}
							startHour="9:00"
                     endHour="18:00"
						/>
						<ViewDirective option="Month" showWeekNumber={true} />
						<ViewDirective
							option="TimelineWeek"
							startHour="09:00"
							endHour="18:00"
						/>
					</ViewsDirective>
					<ResourcesDirective>
						<ResourceDirective
							field="ResourceID"
							title="Resource Name"
							name="Resources"
							textField="Name"
							idField="Id"
							colorField="Color"
							dataSource={resourceDataSource}
						/>
					</ResourcesDirective>
					<Inject
						services={[
							Day,
							Week,
							WorkWeek,
							Month,
							Agenda,
							DragAndDrop,
							Resize,
							TimelineViews,
						]}
					/>
				</ScheduleComponent>
			</div>
			<div className="treeview-title-container mb-3">Lista Prestazioni</div>
			<div className="treeview-component relative flex flex-col items-start">
				<ul>
					{resourceDataSource.map((resource) => (
						<li
							key={resource.Id}
							style={{ color: resource.Color, marginLeft: "20px" }}
						>
							{resource.Name}
							{/* <button
								onClick={() => removeDoctor(resource.Id)}
								className="ml-4"
							>
								<FontAwesomeIcon icon={faMinusSquare} />
							</button> */}
						</li>
					))}
				</ul>
				{/* <div className="input-group relative flex flex-col items-end">
					<div className="absolute translate-y-1/2 self-end mr-2">
						<button
							className="text-right  text-blue-600"
							onClick={addDoctor}
						>
							<FontAwesomeIcon icon={faPlusSquare} size="lg" />
						</button>
					</div>
					<input
						className="ml-5 mt-2 text-black p-1"
						type="text"
						value={newDoctorName}
						onChange={(e) => setNewDoctorName(e.target.value)}
						placeholder="Nuovo dottore"
					/>
				</div> */}
			</div>
		</div>
	);
};

export default Appuntamenti;
