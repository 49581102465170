import React, { useState, useMemo } from "react";
//import { Dialog, IconButton } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
	Box,
	Card,
	CardActions,
	CardContent,
	Collapse,
	Button,
	Typography,
	Rating,
	useTheme,
	useMediaQuery,
	Dialog,
	IconButton,
	TextField,
	MenuItem,
} from "@mui/material";
import Header from "components/Header";
import { useGetDentistsQuery } from "state/api";
import Profile from "./Profile";

const Doctor = ({
	_id,
	clinicName,
	name,
	surname,
	gender,
	qualifications,
	experiences,
	//timeSlots,
	reviews,
	biography,
	about,
	averageRating,
	totalRating,
	starRating,
	specialization,
	cellPhone,
	phone,
	ticketPrice,
	photo,
	performance,
	services,
	availability,
	stat,
}) => {
	const theme = useTheme();
	const [isExpanded, setIsExpanded] = useState(false);
	const [isProfileOpen, setIsProfileOpen] = useState(false);

	//console.log("Card _id", _id)

	//console.log("doctorData", doctorData);
	//console.log("performanceData:", performanceData);

	const handleProfileClick = () => {
		setIsProfileOpen(true);
	};

	const handleProfileClose = () => {
		setIsProfileOpen(false);
	};

	return (
		<Card
			sx={{
				backgroundImage: "none",
				backgroundColor: theme.palette.background.alt,
				borderRadius: "0.55rem",
			}}
		>
			<CardContent>
				<Typography
					sx={{ fontSize: 14 }}
					color={theme.palette.secondary[700]}
					gutterBottom
				>
					{specialization}
				</Typography>
				<Typography
					variant="h5"
					component="div"
					style={{ display: "flex", alignItems: "center" }}
				>
					<span style={{ marginRight: "20px" }}>
						{clinicName ? clinicName : `${name} ${surname}`}
					</span>
					<Button
						sx={{
							border: 1,
							borderColor: "rgb(127, 127, 127)",
							marginTop: "5px",
							color: "rgb(127, 127, 127)",
						}}
						onClick={handleProfileClick}
					>
						Apri Profilo
					</Button>
				</Typography>
				<Typography
					sx={{ mb: "1.5rem" }}
					color={theme.palette.secondary[400]}
				>
					{/* {ticketPrice} */}
					{`${"€".repeat(ticketPrice)}`}
				</Typography>
				<Rating value={starRating} readOnly />
				{performance.map((item, index) => (
					<Typography key={index} variant="body2">
						{Object.values(item)
							.filter((value) => value !== "")
							.join(", ")}
					</Typography>
				))}
				{services.map((item, index) => (
					<Typography key={index} variant="body2" sx={{ mt: 2 }}>
						{Object.values(item)
							.filter((value) => value !== "")
							.join(", ")}
					</Typography>
				))}
			</CardContent>
			<CardActions>
				<Button
					variant="primary"
					size="small"
					onClick={() => setIsExpanded(!isExpanded)}
				>
					Vedi Altro
				</Button>
			</CardActions>
			<Collapse
				in={isExpanded}
				timeout="auto"
				unmountOnExit
				sx={{
					color: theme.palette.neutral[300],
				}}
			>
				<CardContent>
					<Typography>id: {_id}</Typography>
					<Typography>Contatti annui: {/* {supply} */}</Typography>
					<Typography>
						Prime visite/anno:{/*  {stat.yearlySalesTotal} */}
					</Typography>
					<Typography>
						Preventivi accettati/anno:{/* {stat.yearlyTotalSoldUnits} */}
					</Typography>
				</CardContent>
			</Collapse>
			{isProfileOpen && (
				<Dialog open={isProfileOpen} onClose={handleProfileClose}>
					<Box className="relative p-2.5">
						<IconButton
							onClick={handleProfileClose}
							className="absolute top-0 right-0 bg-white rounded-full p-1 text-gray-300"
							aria-label="close"
						>
							<HighlightOffIcon />
						</IconButton>
						{/* <Profile doctorData={doctorData} performanceData={performanceData} servicesData={servicesData} /> */}
						<Profile doctorId={_id} />
						{/*  <Profile key={isProfileOpen} doctorId={_id} /> */}
					</Box>
				</Dialog>
			)}
		</Card>
	);
};

const FilterBar = ({ filters, setFilters }) => (
	<Box sx={{ mb: 4, display: "flex", gap: 2 }}>
		<TextField
			label="Nome Studio"
			variant="outlined"
			value={filters.clinicName}
			onChange={(e) =>
				setFilters((prev) => ({ ...prev, clinicName: e.target.value }))
			}
			fullWidth
		/>
		<TextField
			label="Status"
			select
			value={filters.isApproved}
			onChange={(e) =>
				setFilters((prev) => ({ ...prev, isApproved: e.target.value }))
			}
			fullWidth
		>
			<MenuItem value="">Tutti</MenuItem>
			<MenuItem value="approved">Approvato</MenuItem>
			<MenuItem value="pending">Sospeso</MenuItem>
		</TextField>
		<TextField
			label="Sigla Provincia"
			variant="outlined"
			value={filters.state}
			onChange={(e) =>
				setFilters((prev) => ({ ...prev, state: e.target.value }))
			}
			fullWidth
		/>
		<TextField
			label="Sponsor"
			variant="outlined"
			value={filters.sponsor}
			onChange={(e) =>
				setFilters((prev) => ({ ...prev, sponsor: e.target.value }))
			}
			fullWidth
		/>
	</Box>
);

const AllDoctors = () => {
	const { data, isLoading } = useGetDentistsQuery();

	const [filters, setFilters] = useState({
		clinicName: "",
		isApproved: "",
		state: "",
		sponsor: "",
	});

	/* const filteredData = useMemo(
		() =>
			data?.filter(
				(doc) =>
					(filters.clinicName
						? doc.clinicName.includes(filters.clinicName)
						: true) &&
					(filters.isApproved
						? doc.isApproved === filters.isApproved
						: true) &&
					(filters.state ? doc.state === filters.state : true) &&
					(filters.sponsor ? doc.sponsor === filters.sponsor : true)
			),
		[data, filters]
	); */

	const filteredData = useMemo(() => {
		if (!data) return [];
		return data.filter(doc => 
			 (filters.clinicName ? (doc.clinicName && doc.clinicName.toLowerCase().includes(filters.clinicName.toLowerCase())) : true) &&
			 (filters.isApproved ? doc.isApproved === filters.isApproved : true) &&
			 (filters.state ? doc.state === filters.state : true) &&
			 //(filters.sponsor ? doc.sponsor === filters.sponsor : true)
			 (filters.sponsor ? (doc.sponsor && doc.sponsor.toLowerCase().includes(filters.sponsor.toLowerCase())) : true)
		);
  }, [data, filters]);
  

	//const theme = useTheme();
	const isNonMobile = useMediaQuery("(min-width: 1000px)");

	return (
		<Box m="1.5rem 2.5rem">
			<Header
				title="DENTISTI"
				subtitle="Visualizza l'elenco dei dentisti."
			/>
			<FilterBar filters={filters} setFilters={setFilters} />
			{!isLoading && filteredData ? (
				<Box
					mt="20px"
					display="grid"
					gridTemplateColumns="repeat(4, minmax(0, 1fr))"
					justifyContent="space-between"
					rowGap="20px"
					columnGap="1.33%"
					sx={{
						"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
					}}
				>
					{filteredData.map((doc) => (
						<Doctor
							key={doc._id}
							_id={doc._id}
							clinicName={doc.clinicName}
							name={doc.name}
							surname={doc.surname}
							specialization={doc.specialization}
							ticketPrice={doc.ticketPrice}
							performance={doc.performance}
							services={doc.services}
							starRating={doc.starRating}
						/>
					))}
				</Box>
			) : (
				<Typography>Loading...</Typography>
			)}
		</Box>
	);
};

export default AllDoctors;
