import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
//import { BASE_URL } from "config";
import { AuthContext } from "context/AuthContext";
import { toast } from "react-toastify";
import HashLoader from "react-spinners/HashLoader";
//import Cookies from "js-cookie";
import { useLazyGetUserByEmailQuery } from "state/api"; // Aggiusta il percorso se necessario

const Login = ({ onSuccess }) => {
	const [formData, setFormData] = useState({
		email: "",
		password: "",
	});
	//const [trigger, { data: user, isLoading, isError }] =
	const [{ data: isLoading }] = useLazyGetUserByEmailQuery();
	//console.log("user", user);

	const { dispatch } = useContext(AuthContext);
	const navigate = useNavigate();

	const handleInputChange = (e) => {
		setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
	};

	// Invio credenziali Login
	const handleSubmit = async (e) => {
		e.preventDefault();
		const response = await fetch(
			`${process.env.REACT_APP_BASE_URL}/management/login`,
			{
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					email: formData.email,
					password: formData.password,
				}),
			}
		);

		const result = await response.json();
		//console.log("token", result.token);
		//console.log("result", result);
		if (response.ok) {
			dispatch({
			type: "LOGIN_SUCCESS",
			payload: {
				user: result.data,
				token: result.token,
				role: result.role,
			},
			});

			//console.log("Login > LOGIN_SUCCESS");
			toast.success("Accesso riuscito!");
			navigate("/dashboard");

			 // Chiama la callback per chiudere il popup
			 if (onSuccess) {
				onSuccess();
			 }

		} else {
			toast.error(result.message || "Credenziali non valide.");
		}
	};

	return (
		<section className="px-5 md:p-0 bg-gray-50">
			<div className=" w-full max-w-[570px] mx-auto  my-auto rounded-lg shadow-lg md:p-10 bg-slate-50">
				<div>
					<h3 className="text-headingColor text-[22px] leading-9 font-bold mb-10">
						Ciao!{" "}
						<span className="text-[#0067FF]">Felice di vederti</span> 🎉
					</h3>
					<form onSubmit={handleSubmit} className="py-4 md:py-0">
						<div className="mb-5">
							<input
								type="email"
								value={formData.email}
								onChange={handleInputChange}
								name="email"
								placeholder="Inserisci la tua Email"
								className="w-full px-4 py-3 border-b border-solid border-[#0066ff61] focus:outline-none focus:border-b-[#0067FF] text-[16px] leading-7 text-headingColor placeholder:text-textColor"
								required
							/>
						</div>

						<div className="mb-5">
							<input
								type="password"
								value={formData.password}
								onChange={handleInputChange}
								name="password"
								placeholder="Password"
								className="w-full px-4 py-3 border-b border-solid border-[#0066ff61] focus:outline-none focus:border-b-[#0067FF] text-[16px] leading-7 text-headingColor placeholder:text-textColor"
								required
								autoComplete="current-password"
							/>
						</div>

						<div className="mt-7">
							<button
								type="submit"
								disabled={isLoading && true}
								className="w-full bg-[#0067FF] text-white py-3 px-4 rounded-lg text-[18px] leading-[30px]"
							>
								{isLoading ? (
									<HashLoader size={25} color="#fff" />
								) : (
									"Login"
								)}
							</button>
						</div>

						<p className="mt-5 text-gray-600 text-center">
							Non hai un account?
							<Link
								to="/register"
								className="text-[#0067FF] font-medium ml-1"
							>
								Registrati
							</Link>
						</p>
					</form>
				</div>
			</div>
		</section>
	);
};

export default Login;
