import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useGetEventiQuery } from "state/api";
import Header from "components/Header";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";

const Eventi = () => {
  const theme = useTheme();

  // values to be sent to the backend
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [sort, setSort] = useState({});
  const [search, setSearch] = useState("");

  const [searchInput, setSearchInput] = useState("");
  const { data, isLoading } = useGetEventiQuery({
    page,
    pageSize,
    sort: JSON.stringify(sort),
    search,
  });

  console.log('data', data);

  const columns = [
   /*  {
      field: "_id",
      headerName: "ID",
      flex: 1,
    }, */
    {
      field: "doctor",
      headerName: "Dentista",
      flex: 1,
      valueGetter: (params) => {
        const doctor = params.row.doctor;
        if (doctor) {
          return `${doctor.name} ${doctor.surname || ""}`;
        }
        return "";
      },
    },
    {
      field: "user",
      headerName: "Paziente",
      flex: 1,
      valueGetter: (params) => {
        const user = params.row.user;
        if (user) {
          return `${user.name} ${user.surname || ""}`;
        }
        return "";
      },
    },
    {
      field: "bookingCode",
      headerName: "Codice",
      flex: 1,
    }, 
    /* {
      field: "dentista",
      headerName: "Dentista",
      flex: 1,
      renderCell: (params) => {
        const dentista = params.row.dentista;
        return dentista && dentista[0] ? dentista[0].nome + " " + dentista[0].cognome : "";
      },
    },
    {
      field: "paziente",
      headerName: "Paziente",
      flex: 1,
      renderCell: (params) => {
        const paziente = params.row.paziente;
        return paziente && paziente[0] ? paziente[0].nome + " " + paziente[0].cognome : "";
      },
    },
    */
    {
      field: "bookingPerformance",
      headerName: "Prestazione",
      flex: 1,
    },
    /*
    {
      field: "prima_visita",
      headerName: "Prima visita",
      flex: 1,
    },
    {
      field: "preventivo_accettato",
      headerName: "Preventivo",
      flex: 1,
    },
    */
    {
      field: "createdAt",
      headerName: "Data",
      flex: 1,
      valueFormatter: (params) => {
        const rawDate = params.value;
        if (rawDate) {
          const formattedDate = new Date(rawDate);
          const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
          return formattedDate.toLocaleDateString('it-IT', options);
        }
        return "null"; // Se createdAt è vuoto o non definito
      },
    },
     
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="ESITO CONTATTI" subtitle="Lista esito contatto, prima visita e preventivo" />
      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          rows={(data && data.datiEvento) || []}
          columns={columns}
          rowCount={(data && data.total) || 0}
          rowsPerPageOptions={[20, 50, 100]}
          pagination
          page={page}
          pageSize={pageSize}
          paginationMode="server"
          sortingMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onSortModelChange={(newSortModel) => setSort(...newSortModel)}
          components={{ Toolbar: DataGridCustomToolbar }}
          componentsProps={{
            toolbar: { searchInput, setSearchInput, setSearch },
          }}
        />
      </Box>
    </Box>
  );
};

export default Eventi;
