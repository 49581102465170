import React from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { themeSettings } from "theme";
import Layout from "scenes/layout";
import Dashboard from "scenes/dashboard";
import Dentisti from "scenes/dentisti";
import Customers from "scenes/customers";
import Transactions from "scenes/transactions";
import Eventi from "scenes/eventi";
import Appuntamenti from "scenes/appuntamenti";
import Geography from "scenes/geografia";
import Home from "scenes/home";
import Login from "components/Login";
import Admin from "scenes/admin";
import { AuthContextProvider } from "context/AuthContext";
import ProtectedRoute from "routes/ProtectedRoute";


function App() {
	const mode = useSelector((state) => state.global.mode);
	const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);


	return (
		<div className="app">
			<BrowserRouter>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<AuthContextProvider>
						<Routes>
							<Route element={<Layout />}>
								<Route path="/" element={<Navigate to="/home" replace />} />
								<Route path="/home" element={<Home />} />
								<Route path="/login" element={<Login />} />
								
								<Route path="/dashboard" element={
									<ProtectedRoute allowedRoles={["admin"]}>
										<Dashboard />
									</ProtectedRoute>
								} />
								<Route path="/dentisti" element={
									<ProtectedRoute allowedRoles={["admin"]}>
										<Dentisti />
									</ProtectedRoute>
								} />
								<Route path="/customers" element={
									<ProtectedRoute allowedRoles={["admin"]}>
										<Customers />
									</ProtectedRoute>
								} />
								<Route path="/transactions" element={
									<ProtectedRoute allowedRoles={["admin"]}>
										<Transactions />
									</ProtectedRoute>
								} />
								<Route path="/eventi" element={
									<ProtectedRoute allowedRoles={["admin"]}>
										<Eventi />
									</ProtectedRoute>
								} />
								<Route path="/appuntamenti" element={
									<ProtectedRoute allowedRoles={["admin", "doctor"]}>
										<Appuntamenti />
									</ProtectedRoute>
								} />
								<Route path="/geografia" element={
									<ProtectedRoute allowedRoles={["admin"]}>
										<Geography />
									</ProtectedRoute>
								} />
								<Route path="/admin" element={
									<ProtectedRoute allowedRoles={["admin"]}>
										<Admin />
									</ProtectedRoute>
								} />
							</Route>
						</Routes>
					</AuthContextProvider>
				</ThemeProvider>
			</BrowserRouter>
		</div>
	);
}

export default App;
