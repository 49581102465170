export const formatDate = (date, config) => {
   // format date
   const defaultOptions = { year: 'numeric', month: 'long', day: 'numeric' };
   const options = config ? config : defaultOptions;
 
   // Crea un oggetto Date basato sull'input fornito
   const dateObj = new Date(date);
 
   // Controlla se la data è valida
   if (isNaN(dateObj.getTime())) {
     // Se la data non è valida, restituisci un messaggio personalizzato
     return "Data da definire";
   } else {
     return new Date(date).toLocaleDateString("it-IT", options);
   }
 };
 
 
 
 
 /* export const formatDate = (date, config) => {
   const defaultOptions = {
     year: 'numeric',
     month: 'long',
     day: 'numeric',
     hour: '2-digit',
     minute: '2-digit',
     hour12: false
   };
 
   const options = config ? { ...defaultOptions, ...config } : defaultOptions;
 
   // Crea un oggetto Date basato sull'input fornito
   const dateObj = new Date(date);
 
   // Controlla se la data è valida
   if (isNaN(dateObj.getTime())) {
     // Se la data non è valida, restituisci un messaggio personalizzato
     return "Data non valida";
   }
 
   // Se la data è valida, formattala e restituiscila
   return dateObj.toLocaleDateString("it-IT", options);
 }; */
 
 