import React, { useState } from "react";
import { Outlet } from "react-router-dom";
//import { useSelector } from "react-redux";
import Navbar from "components/Navbar";
import Sidebar from "components/Sidebar";
//import { useGetUserQuery } from "state/api";
import { useMediaQuery } from "@mui/material";
import { useContext } from 'react';
import { AuthContext } from 'context/AuthContext';


const Layout = () => {
  const { user, role } = useContext(AuthContext);
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  //const userId = useSelector((state) => state.global.userId);
  //const { data } = useGetUserQuery(userId);

  //console.log("Layout > userId", userId);
  //console.log("data", data);

  return (
    <div className={`${isNonMobile ? "flex" : "block"} w-full h-full`}>
      <Sidebar
        user={user || {}}
        role={role || ""}
        isNonMobile={isNonMobile}
        drawerWidth="250px"
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />

      <div className="flex-grow">
        <Navbar
          user={user || {}}
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />

        <div className="overflow-auto h-[calc(100vh-64px)] mt-[64px]">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
