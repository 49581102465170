// Dashboard
/* eslint-disable no-unused-vars */
import React from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import {
  DownloadOutlined,
  Email,
  PointOfSale,
  PersonAdd,
  Traffic,
  PeopleAlt,
  LocalHospital,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import BreakdownChart from "components/BreakdownChart";
import OverviewChart from "components/OverviewChart";
import { 
  useGetDashboardQuery, 
  useGetCustomersQuery,
  useGetDentistsQuery,
  useGetSponsorQuery,
} from "state/api";
import StatBox from "components/StatBox";

const Dashboard = () => {
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const { data, isLoading } = useGetDashboardQuery();
  const { data: customerData, isLoading: loadingC } = useGetCustomersQuery();
  const { data: dentistData, isLoading: loadingD } = useGetDentistsQuery();
  const { data: sponsorData, isLoading: loadingS } = useGetSponsorQuery();

  //console.log("dentistData", dentistData);
  //console.log("sponsorData", sponsorData);

  // Calcoli
  const customerCount = customerData ? customerData.length : 0;
  const dentistCount = dentistData ? dentistData.length : 0;


  const columns = [
    {
      field: "promoCode",
      headerName: "Code",
      flex: 0.5,
    },
    {
      field: "name",
      headerName: "Sponsor",
      flex: 1,
    },
    {
      field: "subMonth",
      headerName: "Durata",
      flex: 0.5,
    },
    {
      field: "active",
      headerName: "Attivabile",
      flex: 0.5,
    },
    {
      field: "subscription",
      headerName: "Abbonamento",
      flex: 0.5,
    },
    {
      field: "clinicName",
      headerName: "Nome Studio",
      flex: 1,
    },

    /* {
      field: "products",
      headerName: "# of Products",
      flex: 0.5,
      sortable: false,
      renderCell: (params) => params.value.length,
    },
    {
      field: "cost",
      headerName: "Cost",
      flex: 1,
      renderCell: (params) => `$${Number(params.value).toFixed(2)}`,
    }, */
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Header title="DASHBOARD" subtitle="Benvenuto nella tua dashboard" />

        <Box>
          <Button
            sx={{
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlined sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box>
      </FlexBetween>

      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="160px"
        gap="20px"
        sx={{
          "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 12" },
        }}
      >
        {/* ROW 1 */}
        <StatBox
          title="Totale Pazienti"
          value={customerCount}  
          increase="+%"
          description="Dall'ultimo mese"
          icon={
            <PeopleAlt
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
        <StatBox
          title="Totale Dentisti"
          value={dentistCount}  
          increase="+%"
          description="Dall'ultimo mese"
          icon={
            <LocalHospital
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={theme.palette.background.alt}
          p="1rem"
          borderRadius="0.55rem"
        >
         {/*  <OverviewChart view="sales" isDashboard={true} /> */}
        </Box>
        <StatBox
          title="Vendite mensili"
          value={data && data.thisMonthStats.totalSales}
          increase="+%"
          description="Dall'ultimo mese"
          icon={
            <PersonAdd
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
        <StatBox
          title="Vendite annuali"
          value={data && data.yearlySalesTotal}
          increase="+%"
          description="Dall'ultimo mese"
          icon={
            <Traffic
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />

        {/* ROW 2 */}
        <Box
          gridColumn="span 8"
          gridRow="span 3"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
              borderRadius: "5rem",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.background.alt,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <DataGrid
            loading={loadingS || !sponsorData}
            getRowId={(row) => row._id}
            /* rows={(sponsorData && data.transactions) || []} */
            rows={(sponsorData) || []}
            columns={columns}
          />
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 3"
          backgroundColor={theme.palette.background.alt}
          p="1.5rem"
          borderRadius="0.55rem"
        >
          <Typography variant="h6" sx={{ color: theme.palette.secondary[100] }}>
            Vendite per Categoria
          </Typography>
          <BreakdownChart isDashboard={true} />
          <Typography
            p="0 0.6rem"
            fontSize="0.8rem"
            sx={{ color: theme.palette.secondary[200] }}
          >
            Ricavi da abbonamenti Regular/Premium.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
